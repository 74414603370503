import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a
          className="App-link"
          href="https://www.recipefunnel.com"
          target="_blank"
          rel="noopener noreferrer">
          Recipe Funnel
        </a>

        {/*<a*/}
        {/*    className="App-link"*/}
        {/*    href="https://www.learningplatform.in"*/}
        {/*    target="_blank"*/}
        {/*    rel="noopener noreferrer">*/}
        {/*  Learning Platform*/}
        {/*</a>*/}

        <a
            className="App-link"
            href="https://creditcard.vivekagarwal.com"
            target="_blank"
            rel="noopener noreferrer">
          Credit Card
        </a>

        <a
            className="App-link"
            href="https://www.lazytravller.com/"
            target="_blank"
            rel="noopener noreferrer">
          Lazy Travller
        </a>

        <a
            className="App-link"
            href="https://history.vivekagarwal.com"
            target="_blank"
            rel="noopener noreferrer">
          History
        </a>

        <a
            className="App-link"
            href="https://play.google.com/store/apps/details?id=vivz.scrabblesolver"
            target="_blank"
            rel="noopener noreferrer">
          Scrabble Solver
        </a>

        <a
            className="App-link"
            href="https://play.google.com/store/apps/details?id=com.wordlle"
            target="_blank"
            rel="noopener noreferrer">
          Wordle
        </a>
      </header>
    </div>
  );
}

export default App;
